import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { controlModelToValidationObject } from '../helpers';
import { Component, OOPages } from '../interfaces';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { OOControlModel } from './ControlModel';

type Condition = {
  control: Record<string, any>;
  value?: any;
  notValue?: any;
};

export type JumpCondition = {
  jumpTo: string;
  setCompleted?: boolean;
  conditions: Condition[];
};

interface ConstructorParams {
  name: OOPages;
  displayName: string;
  label: string;
  description: string;
  controls: OOControlModel[];
  skip?: boolean;
  isLastPage?: boolean;
  jumpCondition?: JumpCondition;
  isMandatory: boolean;
  isVisibleCandidate: boolean;
  isVisibleRecruiter: boolean;
  completeOnEnter?: boolean;
}

export class OOPageModel {
  public name: OOPages;
  public displayName: string;
  public label: string;
  public description: string;
  public controls: OOControlModel[];
  public skip?: boolean;
  public jumpCondition?: JumpCondition;
  public isLastPage?: boolean;
  public isMandatory: boolean;
  public isVisibleCandidate: boolean;
  public isVisibleRecruiter: boolean;
  public completeOnEnter?: boolean;

  constructor({
    name,
    displayName,
    label,
    description,
    controls,
    skip = false,
    jumpCondition,
    isLastPage,
    isMandatory,
    isVisibleCandidate,
    isVisibleRecruiter,
    completeOnEnter,
  }: ConstructorParams) {
    this.name = name;
    this.displayName = displayName;
    this.label = label;
    this.description = description;
    this.controls = controls;
    this.skip = skip;
    this.jumpCondition = jumpCondition;
    this.isLastPage = isLastPage;
    this.isMandatory = isMandatory;
    this.isVisibleCandidate = isVisibleCandidate;
    this.isVisibleRecruiter = isVisibleRecruiter;
    this.completeOnEnter = completeOnEnter;
  }

  public static fromJson(json: any): OOPageModel {
    const {
      name,
      displayName,
      label,
      description,
      skip,
      jumpCondition,
      isLastPage,
      isMandatory,
      isVisibleCandidate,
      isVisibleRecruiter,
      completeOnEnter,
    } = json;
    const controls = json.controls.map(OOControlModel.fromJson);
    return new OOPageModel({
      name,
      displayName,
      label,
      description,
      controls,
      skip,
      jumpCondition,
      isLastPage,
      isMandatory,
      isVisibleCandidate,
      isVisibleRecruiter,
      completeOnEnter,
    });
  }

  public getInitialValues(flowWrapper: OOFlowWrapper): Record<string, any> {
    return this.controls
      .filter((c) => c.component !== Component.StaticText)
      .reduce((acc: Record<string, any>, control) => {
        if (control.subControls?.length) {
          control.subControls?.forEach((subControl) => {
            if (!Array.isArray(subControl)) {
              acc[subControl.name] = flowWrapper.getUserValueOrDefault(subControl.name, subControl.type);
            } else {
              subControl.forEach((item: OOControlModel) => {
                acc[item.name] =
                  item.value ?? flowWrapper.getUserValueOrDefault(item.originalName || item.name, item.type);
              });
            }
          });
        } else {
          acc[control.name] = flowWrapper.getUserValueOrDefault(control.name, control.type);
        }

        return acc;
      }, {});
  }

  public getValidationObject(
    flowWrapper: OOFlowWrapper,
    t: TFunction,
    formValues?: Record<string, any>,
    initialErrors?: Map<string, any>,
    manualErrors?: Map<string, any>,
  ): Record<string, any> {
    return controlModelToValidationObject(
      this.controls,
      t,
      true,
      flowWrapper,
      this.controls?.[0]?.label?.split('.')[0].toLowerCase() || 'entry',
      formValues,
      initialErrors,
      manualErrors,
    );
  }

  public getValidationSchema(
    flowWrapper: OOFlowWrapper,
    t: TFunction,
    formValues?: Record<string, any>,
    initialErrors?: Map<string, any>,
    manualErrors?: Map<string, any>,
  ): Yup.ObjectSchema {
    return Yup.object(this.getValidationObject(flowWrapper, t, formValues, initialErrors, manualErrors));
  }
}
