import { useMemo } from 'react';

import { OOSteps, StepStatusEnum } from '../interfaces';
import { OOStepModel } from '../models/StepModel';
import { getRecruiterSteps, OOFlowWrapper } from '../wrappers/FlowWrapper';

const STEPS_TO_SHOW: OOSteps[] = [
  'IMPORT',
  'REGISTRATION',
  'CANDIDATE_DETAILS',
  'DOCUMENT_UPLOAD',
  'DOCUMENT_UPLOAD2',
  'REQUEST_CONFIRMATION',
  'CANDIDATE_CONFIRMS_DOCUMENTS',
  'CANDIDATE_SIGN_DOCUMENTS',
  'AWAIT_CONTRACT',
  'HIRE',
  'RECRUITER_SIGN_DOCUMENTS',
  'HIRE_SIGNATURE',
];
const STEPS_TO_HIDE: OOSteps[] = ['MATCH'];

const getSteps = (
  flowWrapper: OOFlowWrapper | undefined,
  currentStep: string | undefined,
  filterFn: (step: OOStepModel) => boolean,
) => {
  if (flowWrapper === undefined || currentStep === undefined) {
    return [];
  }

  return getRecruiterSteps(flowWrapper.steps)
    .filter(filterFn)
    .map((step) => {
      const currentStepInArray = flowWrapper.getStep(currentStep);
      const checkStep = flowWrapper.getStep(step.name);
      if (!currentStepInArray || !checkStep) {
        return step;
      }
      const beforeCurrentSteps = flowWrapper.steps.slice(0, flowWrapper.steps.indexOf(currentStepInArray));

      if (beforeCurrentSteps.includes(checkStep)) {
        step.status = StepStatusEnum.completed;
        step.hide = false;
      } else if (checkStep === currentStepInArray) {
        step.status = StepStatusEnum.inProgress;
        step.hide = false;
      } else {
        step.status = StepStatusEnum.notStarted;
        step.hide = true;
      }

      return step;
    });
};

export const useSteps = (flowWrapper: OOFlowWrapper | undefined, currentStep: string | undefined) => {
  return useMemo(
    () =>
      getSteps(flowWrapper, currentStep, (s) => {
        if (STEPS_TO_HIDE.includes(s.name)) {
          return false;
        } else {
          return STEPS_TO_SHOW.includes(s.name) || s.documentations.length > 0 || s.pages.length > 0;
        }
      }),
    [flowWrapper, currentStep],
  );
};

export const useAllSteps = (flowWrapper: OOFlowWrapper | undefined, currentStep: string | undefined) => {
  return useMemo(
    () =>
      getSteps(flowWrapper, currentStep, (s) => {
        return STEPS_TO_SHOW.includes(s.name) || s.documentations.length > 0 || s.pages.length > 0;
      }),
    [flowWrapper, currentStep],
  );
};
